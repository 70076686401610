@import 'styles/styles';
@import 'styles/animations';

.resultLoader {
  box-shadow: $cardBoxShadow;
  display: flex;
  height: 180px;
  margin-bottom: 1rem;

  @include mediaQuery(max, $mobile) {
    flex-direction: column;
    height: auto;
  }
}

.resultImgContainer {
  width: 100%;
}

.resultImg {
  @include backgroundGradient;
  max-width: 100%;

  animation: panel 4s infinite linear;
  flex: 0 0 38%;
  height: 100%;
  max-width: 300px;

  @include mediaQuery(max, $mobile) {
    flex: none;
    height: 190px;
    max-width: 100%;
  }
}

.resultContent {
  flex: 0 0 62%;
  padding: 24px;
  max-width: 62%;

  @include mediaQuery(max, $mobile) {
    flex: none;
    max-width: 100%;
  }
}

.resultContentTop {
  margin-bottom: 2rem;

  div:first-of-type {
    float: right;

    @include mediaQuery(max, $mobile) {
      display: none;
    }
  }
}

.resultContentBottom {
  display: flex;
  justify-content: space-between;
}
