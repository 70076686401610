@import '../../styles/styles';

.favoriteListingButton {
  background-color: $white;
  position: absolute;
  border: none;
  padding: 0rem;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $favoriteBtnBoxShadow;
  transition: all 0.15s ease;

  // No hover state on detail page desktop to match share button
  &.unselected:not(.detailOverview) {
    &:hover {
      background-color: $grey-very-light;
    }
  }

  svg {
    pointer-events: none;
    width: 1.25rem;
    height: 1.25rem;
    margin: 2px 1px 0px 1px;

    &.emptyHeart {
      g {
        fill: $white;
      }
    }
  }
}

// Search results list
.algoliaResult {
  box-shadow: none;
  right: calc(24px - 0.75rem / 2 + 18px);
  padding: 6px;
  margin: -12px;
  margin-top: 8px;
  background-color: transparent;

  @include mediaQuery(max, 1000px) {
    right: calc(2.25rem + 15px);
    margin-top: 2px;
  }

  @include mediaQuery(max, 600px) {
    background-color: $white;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    margin-top: 10px;
    margin-right: 0px;
    right: calc(25px + 1rem);
    z-index: 1;
  }
}

.smallButton {
  z-index: 10;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
}

// Search desktop marker
.algoliaMarker {
  @extend .smallButton;

  right: 10px;
  top: 10px;
}

// Search mobile marker
.mapResult {
  @extend .smallButton;

  position: relative;
  align-self: flex-end;
  top: 45px;
  right: 10px;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
}

// Result details desktop
.detailOverview {
  @include noWebkitHilight;

  &:focus {
    border-color: $gainsboro;
    outline: none;
  }

  margin-right: 15px;
  position: relative;
  background-color: $white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: 1px solid $gainsboro;
  font-weight: 400;
  font-size: 1rem;
  padding: 8px 16px;

  @include mediaQuery(max, $mobile) {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    margin-right: 10px;
  }
}

// Result details mobile
.resultDetails {
  @include noWebkitHilight;

  z-index: 2;
  width: 2.5rem;
  height: 2.5rem;
  right: 15px;
  margin-top: 15px;
}

// Favorites page
.favoritesPage {
  @extend .algoliaResult;

  @include mediaQuery(max, 1000px) {
    right: calc(24px - 0.75rem / 2 + 15px);
    margin-top: 2px;
  }

  @include mediaQuery(max, $tablet) {
    right: calc(24px - 0.75rem / 2);
    margin-top: 2px;
  }

  @include mediaQuery(max, $mobile + 30) {
    background-color: $white;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    margin-top: 10px;
    margin-right: 0px;
    right: 1rem;
    z-index: 1;
  }
}

// Listing Carousel (Auto Location Landing page)
.listingCarousel {
  @extend .resultDetails;
  right: 40px;
}

.toast {
  color: $saldova;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  box-shadow: $favoriteToastBoxShadow;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  svg {
    height: 13px;
    width: 13px;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 1.75rem 0.5rem;
  }
}

// Results detail save/saved text
@keyframes fadeOutAndIn {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fadeOutAndIn {
  opacity: 1;
  animation: fadeOutAndIn 1s ease;
}

// Heart pulse animation
/* ----------------------------------------------
 * Generated by Animista on 2021-2-2 15:7:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes pulsateFwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pulsateFwd {
  animation: pulsateFwd 0.5s ease-in-out both;
}
