@import 'styles/styles';

.embla {
  background-color: #f7f7f7;
  position: relative;
  width: 100%;
  &:hover {
    .embla__button__next,
    .embla__button__prev {
      visibility: visible;
    }
  }
  &.embla__dots {
    position: absolute;
    margin-bottom: 15px;
  }
}

.dynamicHeight {
  height: 100%;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
  min-width: 100%;
  overflow: hidden;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button__disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button__prev {
  left: 10px;
  visibility: hidden;
  @include mediaQuery(max, $mobile) {
    display: none;
  }
}

.embla__button__next {
  right: 10px;
  visibility: hidden;
  @include mediaQuery(max, $mobile) {
    display: none;
  }
}

.embla__dots {
  bottom: 10px;
  background-color: transparent;
  width: 84px;
  left: 50%;
  transform: translateX(-50%);

  .embla__container {
    cursor: default;
    min-width: 20%;
  }

  .embla__slide {
    position: relative;
    min-width: 20%;
    height: 8px;
  }
}

.embla__dot {
  cursor: pointer;
  outline: 0;
  border: 0;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  position: absolute;
  top: 1px;

  &:after {
    background-color: #efefef;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    content: '';
    display: block;
    margin: auto;
  }

  &.is_selected {
    top: 0px;
    &:after {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  }
}

.mapImage {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
