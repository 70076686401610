@import '../../../styles/styles';

.emptyHeart {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1.5rem;
}

.loginPromptHeading {
  font-family: $font-primary;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 1.5rem;
  line-height: 21px;

  &.longText {
    @include mediaQuery(min, $tablet) {
      max-width: 80%;
    }
  }
}

.createAccountBtn {
  margin-bottom: 1.5rem;

  @include mediaQuery(min, $tablet) {
    padding: 17px 4rem;
  }
}

.accountCopy,
.startSearchCopy {
  font-family: $font-primary;
  margin: 0px 0px 10px;
  font-size: 15px !important;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
}

.customLink {
  font-weight: 600;
  font-size: 16px;
}

.signInBtn {
  @extend .customLink;
  margin-bottom: 1.5rem;
}

.saveAsGuestBtn {
  color: $capeCoral;
  text-decoration: underline;
  margin-bottom: 1.5rem;
}

.loginPromptModalWrapper {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.loginPromptModal {
  z-index: 8;
  background-color: $white;
  box-shadow: $cardBoxShadow;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 45vw;
  max-width: 600px;

  .signInBtn {
    margin-bottom: 0rem;
  }

  @include mediaQuery(max, $mobile) {
    width: 65vw;
    min-width: 300px;
  }
}

.loginPromptModalBody {
  height: 100%;
  overflow: auto;
  padding: 4rem 2rem;
}

.loginPromptModalTop {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.loginPromptMain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.modalClose {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem;

  &.responsiveCloseIcon {
    padding: 0.9rem;

    svg {
      width: 20px;
      height: 20px;
    }

    @include mediaQuery(max, $tablet) {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

// Modal fade in
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn ease 0.3s;
}
