@import '../../styles/styles';

.pagination {
  margin-top: 3rem;
}

.pages {
  align-items: flex-start;
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin: 0 0 1rem;
  padding: 0;

  li {
    border-radius: 50%;
    color: $grey-dot-active;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 400;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0 0.25rem;
    text-align: center;
    width: 1.5rem;

    @include mediaQuery(min, $smDesktop) {
      font-size: 0.875rem;
      height: 2rem;
      line-height: 2rem;
      margin: 0 0.5rem;
      width: 2rem;
    }
    &.active {
      background-color: $grey-dot-active;
      color: $white;

      a {
        color: $white;
      }
    }
    &.elipsis {
      margin: 0;
    }
    &.disabled {
      cursor: default;
    }
  }
  a {
    color: $grey-dot-active;
    display: block;
    height: 1.5rem;
    text-decoration: none;
    width: 1.5rem;

    @include mediaQuery(min, $smDesktop) {
      height: 2rem;
      width: 2rem;
    }
  }
}

.paginationArrow {
  height: auto;
  width: 100%;
}

.overview {
  color: $casal;
  font-size: 0.875rem;
  text-align: center;
}
