@import 'styles/styles';
@import 'styles/animations';

.barLoader {
  @include backgroundGradient;

  &.transparent {
    @include backgroundGradientTransparent;
  }

  animation: panel 4s infinite linear;
  max-width: 100%;
}
